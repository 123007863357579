#layout {
  margin: 30px auto 0 auto;
  width: 960px;
  font-family: 'Helvetica Neue', Arial;
  font-size: 13px;

  #body {
    clear: both;

    .kof-form {
      > .row {
        display: flex;

        > label {
          width: 150px;
          flex: none; // strangely if next div is of checkbox form type, label width is not applied.
          padding-top: 5px;
        }

        > div.element.checkbox {
          padding-top: 8px;
        }

        > div.element.component {
          padding-top: 5px;
        }
      }
    }

    .kof-text-editor {
      width: 540px;
    }

    .kof-table table td,
    .kof-table table th {
      padding: 5px;
    }

    .kof-table table th.number {
      width: 65px;
    }
  }
}