@import "@kof/react-commons/src/style/variables.scss";

#page.papers-activation-page {

  .kof-table table {
    table-layout: fixed;

    th:nth-child(2) {
      width: 200px;
    }
  }

  .kof-button {
    margin-top: $spacing1;
  }
}