@import "../../variables.scss";

#navigation {
  height: 30px;
  background: #8484BD;
  padding-left: 120px;

  a {
    color: #fff;
    font-weight: bold;
    text-decoration: none;
    padding: 5px 15px 6px 15px;
    float: left;
    border-left: 1px solid #A4A4DD;
    border-right: 1px solid #A4A4DD;
    border-radius: 5px 5px 0 0;
    margin-right: 4px;

    &:hover,
    &.active {
      background: white;
      color: $red;
    }
  }
}