.papers-admin-page {
  .kof-table {
    svg.zip-download {
      margin-top: 1px;
    }

    table {
      table-layout: fixed;

      th:nth-child(2) {
        width: 230px;
      }
    }
  }
}