@import "../../variables.scss";

#content {
  padding: 20px 40px;
  width: 650px;
  float: left;
  font-size: 13px;

  a {
    color: #00489A;
    text-decoration: none;

    &:hover {
      text-decoration: initial;
    }
  }

  h3,
  .kof-text-editor .heading-3 span {
    color: $red;
    font-size: 16px;
    margin: 0 0 10px 0;
  }

  .kof-text-editor .heading-3 span {
    margin: 0;
  }

  h4,
  .kof-text-editor .heading-4 span {
    font-size: 14px;
    font-style: italic;
    margin-bottom: 10px;
  }

  .kof-text-editor .heading-4 span {
    margin: 0px;
  }

  h5,
  .kof-text-editor .heading-5 span {
    font-size: 12px;
  }
}