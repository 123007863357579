.files-admin-page {
  .kof-table {
    table {
      table-layout: fixed;

      th:first-child,
      td:first-child {
        width: 70%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}