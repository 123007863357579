@import "@kof/react-commons/src/style/variables.scss";

#conference-images {
  display: flex;
  flex-wrap: wrap;

  .item {
    width: 120px;
    margin-bottom: $spacing1;

    .image {
      height: 100px;
      margin-right: $spacing1;

      display: flex;
      justify-content: center;
      align-items: center;

      border: 1px solid grey;
      position: relative;

      .remove {
        position: absolute;
        top: 0px;
        right: 0px;
      }

      img {
        max-width: 90%;
        max-height: 90%;
        object-fit: contain;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}