@import "@kof/react-commons/src/style/variables.scss";
@import "../../variables.scss";

#sidebar {
  width: 190px;
  min-height: 700px;
  background: #F0F0F0;
  float: left;
  padding: 20px;

  a {
    color: black;

    &:hover {
      color: $red;
    }
  }

  #side-navigation {
    ul {
      list-style: none;
      font-weight: bold;

      ul {
        display: none;
      }

      li {
        &.active {
          > .row {
            a {
              color: $red;
            }
          }

          > ul {
            display: block;
          }
        }

        a {
          text-decoration: none;
        }
      }
    }
  }

  .divider {
    border-top: 1px solid grey;
    border-bottom: 0;
    margin: $spacing2 0;
  }
}