#header {
  height: 130px;
  background: #A4A4DD;
  color: white;

  img {
    margin: 22px 100px 0 45px;
    float: left;
  }

  h1 {
    float: left;
    font-size: 28px;
    margin-top: 30px;
    width: 190px;
    margin-right: 10px;
  }

  h2 {
    font-size: 20px;
    margin-top: 30px;
    float: left;
  }
}