#page.rating {
  .kof-table {
    table {
      table-layout: fixed;

      th:nth-child(2) {
        width: 300px;
      }

      th.my-rating {
        width: 60px;
      }
    }
  }

  .kof-form {
    .element.display {
      width: 600px;
      overflow-wrap: break-word;
    }
  }
}